@import "~rivals/styles/breakpoints.scss";
@import "~rivals/styles/variables.scss";
@import "~rivals/styles/typography.scss";

.label {
  padding: 0 $spacing-4;
  text-transform: capitalize;
  @include sizes-ending-with($screen-sm-mobile-max) {
    font-size: 9px;
  }
}

@keyframes loading {
  0% {
    justify-self: left;
    width: 0;
  }

  49% {
    justify-self: left;
    width: calc(100% - 32px);
  }

  51% {
    justify-self: right;
    width: calc(100% - 32px);
  }

  100% {
    justify-self: right;
    width: 0;
  }
}

@keyframes loading-small {
  0% {
    justify-self: left;
    width: 0;
  }

  49% {
    justify-self: left;
    width: calc(100% - 20px);
  }

  51% {
    justify-self: right;
    width: calc(100% - 20px);
  }

  100% {
    justify-self: right;
    width: 0;
  }
}

.menuWithHighlight {
  :global {
    .ant-menu-item-selected::after {
      & :local {
        animation: loading 2s infinite;
        @include sizes-ending-with($screen-lg-mobile-max) {
          animation: loading-small 2s infinite;
        }
      }
    }
  }
}
