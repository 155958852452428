// Breakpoint updates must also be reflected in the BREAKPOINTS enum in frontend/shared/constants.ts
$screen-sm-mobile-min: 1px;
$screen-sm-mobile-max: 359px;
$screen-mobile-min: 360px;
$screen-mobile-standard-min: 375px;
$screen-mobile-max: 489px;
$screen-lg-mobile-min: 490px;
$screen-lg-mobile-max: 767px;
$screen-tablet-min: 768px;
$screen-tablet-max: 1023px;
$screen-sm-desktop-min: 1024px;
$screen-sm-desktop-max: 1439px;
$screen-sm-desktop-updated-max: 1365px;
$screen-med-desktop-min: 1366px;
$screen-med-desktop-max: 1439px;
$screen-lg-desktop-min: 1440px;
$screen-lg-desktop-max: 9999px;

$container-width-desktop: 1009px;
$container-width-desktop-XL: 1366px;

$ant-xl-width: 1200px;

@mixin sizes-starting-with($min) {
  @media screen and (min-width: $min) {
    @content;
  }
}

@mixin sizes-ending-with($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}

@mixin sizes-between($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin is-touchscreen() {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}
